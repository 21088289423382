<template>
  <div
    ref="rootVideoEl"
    class="rootVideo"
    :aria-label="$t('aria.playerName')"
    :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.Common, 'root')"
    :style="{
      overflow: isSmartTV ? 'initial' : 'hidden',
    }"
    :class="{
      mobile: $isMobile,
      [$style.playerMyChannel]: projector === 'my-channel',
      [$style.player]: true,
      [$style.playerAmbient]: isAmbientModeEnabled,
      [$style.playerApple]: $iOS || $isSafari,
      [$style.playerRounded]: isRoundedVideo,
      // Если верить Яндексу, этот класс сообщит вебвизору, что видеоплееры не надо записывать.
      'ym-hide-content': true,
    }"
  >
    <global-handlers-and-variables-injector>
      <video-state-detector />
      <viewport-interaction-manager v-if="isCreated">
        <slot />
      </viewport-interaction-manager>
    </global-handlers-and-variables-injector>
  </div>
</template>

<script lang="ts" setup>
import { isTrue, setCSSVariable, toPixel, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { isFullscreeniOSApiEnabled, isServer } from '@PLAYER/player/base/dom';
import GlobalHandlersAndVariablesInjector from '@PLAYER/player/components/viewport/GlobalHandlersAndVariablesInjector.vue';
import VideoStateDetector from '@PLAYER/player/components/viewport/VideoStateDetector.vue';
import ViewportInteractionManager from '@PLAYER/player/components/viewport/ViewportInteractionManager.vue';
import useDebugActions from '@PLAYER/player/modules/debug/use-debug-actions';
import useExperimentalFeature from '@PLAYER/player/modules/experimental-feature/use-experimental-feature';
import defineGlobalVueProperty from '@PLAYER/player/modules/global/define-global-vue-property';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import { PlayerGlobalProperty, type PlayerProjector } from '@PLAYER/player/modules/instance/interfaces';
import {
  ElementTestIdentifierScope,
  getTestElementIdentifier,
} from '@PLAYER/player/modules/test/get-test-element-identifier';
import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import { isClient, useElementSize } from '@vueuse/core';
import { computed, getCurrentInstance, onMounted, provide, ref, watch } from 'vue';

const props = defineProps<{
  projector: PlayerProjector;
  isRoundedVideo?: boolean;
}>();

const videoConfig = useVideoConfig();

videoConfig['video.projector'] = ref(props.projector).value;

const { requestDebugMenu } = useDebugActions();
const { isAmbientModeEnabled } = useExperimentalFeature();
const app = getCurrentInstance();

defineGlobalVueProperty(app, PlayerGlobalProperty.RequestDebugMenu, requestDebugMenu);

defineGlobalVueProperty(app, PlayerGlobalProperty.RequestKinomEditorMode, () => {
  throw new UnexpectedComponentStateError('$requestKinomEditorMode');
});

const { isSmartTV } = usePlatform();

const rootVideoEl = ref<HTMLDivElement>();
provide('rootVideoEl', rootVideoEl);

const { width, height } = useElementSize(rootVideoEl);

onMounted(() => {
  if (!rootVideoEl.value) {
    throw new UnexpectedComponentStateError('rootVideoEl');
  }

  setCSSVariable(
    'native-ios-fullscreen-enabled',
    isTrue(isFullscreeniOSApiEnabled) ? 'true' : 'false',
    rootVideoEl.value,
  );

  watch(width, (val) => setCSSVariable('root-player-width', toPixel(val), rootVideoEl.value), { immediate: isClient });
  watch(height, (val) => setCSSVariable('root-player-height', toPixel(val), rootVideoEl.value), {
    immediate: isClient,
  });
});

const isCreated = computed(() => Boolean((isClient && rootVideoEl.value) || isServer));
</script>

<style lang="scss">
@import '@PLAYER/player/styles/base';
@import '@PLAYER/player/styles/animations';

.rootVideo {
  --player-default-backdrop-filter-modal-blur: 5px;

  --root-player-width: 0px;
  --root-player-height: 0px;

  --native-ios-fullscreen-enabled: false;

  --s-skeleton-background-size: 50%;
  --g-web-controls-layer-height: 70px;

  --g-player-default-transform-scale: scale(1.5);

  --z-index-next-episode-button-pseudo: -1;
  --z-index-debug-view: 9999999999;
  --z-index-web-content-fullscreen-player: 9999999;
  --z-index-error-view: 15000;
  --z-index-context-menu: 14500;
  --z-index-popup-view: 14000;
  --z-index-tv-media-description-view: 12500;
  --z-index-layer-buttons: 12000;
  --z-index-welcome-view: 11000;
  --z-index-next-episode-view: 10500;
  --z-index-label-tips: 10000;
  --z-index-adult-content-warning: 10000;
  --z-index-kinom-success-layer: 9900;
  --z-index-mobile-background-controls: 9800;
  --z-index-video-controls: 9700;
  --z-index-media-description-view: 9675;
  --z-index-subtitles-layer: 9625;
  --z-index-video-element-manager: 9600;
  --z-index-vr-camera-view: 9550;
  --z-index-video-remote-play-device: 9500;
  --z-index-mobile-background-controls-active: 8950;
  --z-index-kinom-description-view: 8000;
  --z-index-main-loader: 8000;
  --z-index-kinom-preview-layer: 7500;
  --z-index-smart-tv-backgrounds: 7400;
}

.rootVideo > * {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
</style>

<style lang="scss" module>
.player {
  position: relative;
  transform: translateZ(0);
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-color: var(--color-notheme-bg-banner-button-pressed);
  overflow: hidden;
  color: var(--color-notheme-text-primary);
}

@supports (container: size) {
  .player {
    container-type: inline-size;
    container-name: rootVideo;
  }
}

.playerMyChannel {
  --g-player-default-transform-scale: scale(1);
}

.playerFullscreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-web-content-fullscreen-player);
}

.playerApple {
  position: relative;
  transform: translateZ(0);
}

.playerAmbient {
  transform: none;
  background-color: transparent;
}

.playerRounded {
  border-radius: var(--g-border-round-24);
}
</style>
