<template>
  <button
    tabindex="0"
    :title="title"
    :aria-label="ariaLabel"
    :style="{
      transition: buttonTransitionStyle,
    }"
    :class="{
      [$style.appButton]: true,
      [$style.appButtonNoTransition]: platform === 'legacy-smart-tv',
      [$style.smartButton]:
        variation === 'smart-button-secondary' ||
        variation === 'smart-button-primary' ||
        variation === 'smart-player-button-primary',

      [$style.button]:
        variation === 'button-primary' ||
        variation === 'button-secondary' ||
        variation === 'button-ghost' ||
        variation === 'player-button-primary' ||
        variation === 'smart-player-button-primary' ||
        variation === 'smart-button-secondary' ||
        variation === 'smart-button-primary',

      [$style.link]: variation === 'link-primary' || variation === 'link-secondary' || variation === 'link-alert',
      [$style.smartButtonPrimary]: variation === 'smart-player-button-primary' || variation === 'smart-button-primary',
      [$style.smartButtonSecondary]: variation === 'smart-button-secondary',
      [$style.playerButtonPrimary]: variation === 'player-button-primary',

      [$style.primaryButton]: variation === 'button-primary',
      [$style.secondaryButton]: variation === 'button-secondary',
      [$style.primaryLink]: variation === 'link-primary',
      [$style.secondaryLink]: variation === 'link-secondary',

      [$style.alertLink]: variation === 'link-alert',
      [$style.small]: size === 'small',
      [$style.medium]: size === 'medium',
      [$style.large]: size === 'large',
      [$style.extraLarge]: size === 'extra-large',

      [$style.appButtonFocused]: isFocused,
    }"
    :type="type"
    :disabled="isDisabled"
  >
    <transition name="bounce">
      <slot name="loader" />
    </transition>
    <p v-if="!isLoading" :class="$style.textWrapper" :style="{ 'justify-content': textAlign }">
      <slot name="icon" />
      <span :class="[textClassName, { [$style.text]: true }]">
        {{ text }}
      </span>
      <slot name="icon-right" />
    </p>
    <p v-else :class="$style.textWrapper" :style="{ 'justify-content': textAlign }">
      <slot name="icon" />
      <span :class="[textClassName, { [$style.hidden]: true }]">
        {{ text }}
      </span>
      <slot name="icon-right" />
    </p>
  </button>
</template>

<script>
export default {
  props: {
    isTransitionEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    transitionDuration: {
      type: String,
      required: false,
      default: '0.3s',
    },
    /**
     * @type {center | start | end}
     */
    textAlign: {
      type: String,
      required: false,
      default: 'center',
    },
    isFocused: { type: Boolean, required: false, default: false },
    text: { type: String, required: true },
    textClassName: { type: String },
    isLoading: { type: Boolean },
    isDisabled: { type: Boolean },
    title: { type: String, default: '' },
    ariaLabel: { type: String, default: '' },
    /** @type {import('../types')}.AppButtonType */
    type: { type: String, default: 'button' },
    /** @type {import('../types')}.AppButtonVariation */
    variation: { type: String, default: 'button-primary' },
    /** @type {import('../types')}.AppButtonSize */
    size: { type: String, default: 'medium' },
    /** @type {import('../types')}.AppPlatform */
    platform: { type: String, default: 'web' },
  },
  computed: {
    buttonTransitionStyle() {
      if (!this.isTransitionEnabled) {
        return '';
      }

      return `background-color ${this.transitionDuration} ease-in-out, color ${this.transitionDuration} ease-in-out, border-color ${this.transitionDuration} ease-in-out`;
    },
  },
};
</script>

<style lang="scss" module>
@use '../styles/fonts' as webFonts;
@use '../styles/smarttv-fonts' as smartTvFonts;
@use '../styles/adjust-smart-px' as adjust;

.appButton {
  margin: 0;
  border-width: 0;
  font-family: inherit;
  white-space: nowrap;
  text-align: center;

  @include webFonts.WebLabel-2();
}

.appButtonNoTransition {
  transition: none;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--g-border-round-12);

  &:disabled {
    cursor: default;
  }
}

.link {
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}

.button.small {
  padding: var(--g-spacing-8) var(--g-spacing-12);
}

.button.medium {
  padding: var(--g-spacing-12) var(--g-spacing-16);
}

.button.large {
  padding: var(--g-spacing-16) var(--g-spacing-24);
}

.button.extraLarge {
  padding: var(--g-spacing-18) var(--g-spacing-24);
}

.link.small,
.link.medium,
.link.large {
  padding: var(--g-spacing-12);
}

.primaryLink {
  padding: var(--g-spacing-12);
  color: var(--color-text-primary);

  &:hover {
    color: var(--color-states-link-hover);
  }
}

.secondaryLink {
  padding: var(--g-spacing-12);
  color: var(--color-text-secondary);

  &:hover {
    color: var(--color-states-link-hover);
  }
}

.alertLink {
  padding: var(--g-spacing-12);
  color: var(--color-text-negative);
}

.smartButton {
  border-radius: adjust.adjustPx(16px);
  outline: 0;
  cursor: pointer;

  @supports (backdrop-filter: blur(5px)) {
    // TODO: НЕ работает нормально в телевизорах
    // backdrop-filter: blur(5px);
  }

  .text {
    color: inherit;
    @include smartTvFonts.SmartTvLabel-2();
  }
  .hidden {
    color: transparent;
  }
}

.smartButtonPrimary {
  border: 0;
  background-color: var(--color-notheme-bg-secondary-80);
  color: var(--color-notheme-text-primary);

  @supports (backdrop-filter: blur(5px)) {
    // TODO: НЕ работает нормально в телевизорах
    // backdrop-filter: blur(5px);
  }

  &.appButtonFocused {
    background-color: var(--color-notheme-bg-accent);
    color: var(--color-notheme-text-accent);
  }

  // &:focus:not([disabled]),
  // &:hover:not([disabled]) {
  //   background-color: var(--color-notheme-bg-accent);
  //   color: var(--color-notheme-text-accent);
  // }

  &:disabled {
    background-color: var(--color-states-bg-accent-disabled);
    color: var(--color-states-text-disabled);
  }
}

.smartButtonSecondary {
  border: adjust.adjustPx(4px) solid var(--color-stroke-active);
  background-color: transparent;
  color: var(--color-text-primary);

  &.appButtonFocused {
    border-color: var(--color-notheme-bg-accent);
    background-color: var(--color-notheme-bg-accent);
    color: var(--color-notheme-text-accent);
  }

  // &:focus:not([disabled]),
  // &:hover:not([disabled]) {
  //   border-color: var(--color-bg-accent);
  //   background-color: var(--color-notheme-bg-accent);
  //   color: var(--color-notheme-text-accent);
  // }

  &:disabled {
    border-color: var(--color-stroke-field-border);
    color: var(--color-states-text-disabled);
  }
}

.primaryButton {
  background-color: var(--color-bg-accent);
  color: var(--color-notheme-text-accent);
  cursor: pointer;

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background-color: var(--color-states-bg-accent-hover);
  }

  &:disabled {
    background-color: var(--color-states-text-disabled);
    color: var(--color-states-bg-accent-disabled);
  }
}

.playerButtonPrimary {
  background-color: var(--color-notheme-bg-secondary-80);
  color: var(--color-notheme-text-primary);

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background-color: var(--color-notheme-bg-hover-70);
    cursor: pointer;
  }
}

.secondaryButton {
  background-color: var(--color-bg-button);
  color: var(--color-text-primary);

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background-color: var(--color-states-bg-secondary-hover);
    cursor: pointer;
  }

  &:disabled {
    background-color: var(--color-states-bg-secondary-disabled);
    color: var(--color-states-text-disabled);
  }
}

.ghostButton {
  background-color: var(--color-bg-ghost);
  color: var(--color-text-primary);

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background-color: var(--color-states-bg-ghost-hover);
    cursor: pointer;
  }
}

.web {
  .text {
    text-wrap: wrap;

    @include webFonts.WebLabel-2();
  }
}

.loader {
  position: absolute;
  left: calc(66% - 100px);
}

@media (max-width: 360px) {
  .web {
    .button {
      padding: var(--g-spacing-8) var(--g-spacing-16);
    }
  }
}

.textWrapper {
  display: flex;
  align-items: center;
}
</style>
