<template>
  <UIModal>
    <section ref="el" :class="$style.modal">
      <article :class="$style.illustration">
        <div :class="$style.slides">
          <div :class="$style.borderLeftStub" />
          <div :class="$style.cropLeft"><img :class="[$style.pic5, $style.blur20]" data-image :src="slider1" /></div>
          <div :class="$style.pic3Container">
            <img data-image :class="[$style.image, $style.pic3, $style.blur10]" :width="250" :src="slider2" />
          </div>
          <div :class="$style.pic4Container">
            <img data-image :class="[$style.image, $style.pic4, $style.blur10]" :src="slider3" />
          </div>
          <div :class="$style.cropRight"><img :class="[$style.pic6, $style.blur20]" data-image :src="slider1" /></div>
        </div>

        <div :class="[$style.frame, $style.blur42]">
          <div :class="$style.frameSlides">
            <img data-image :class="[$style.image, $style.pic2]" :src="slider2" />
            <img data-image :class="[$style.image, $style.pic1]" :src="slider3" />
          </div>
        </div>
        <div :class="$style.borderRightStub" />
      </article>
      <article :class="$style.text">
        <h3 :class="$style.title">{{ $t('pages.mediaCard.promo.title') }}</h3>
        <h4 :class="$style.subtitle">{{ $t('pages.mediaCard.promo.subtitle') }}</h4>
        <section :class="$style.buttons">
          <NavigatableItem
            :tag="AppButton"
            :class="$style.button"
            :active-class="$style.active"
            :text="primaryButtonText"
            :focus-key="FocusKeys.PROMO_BUTTON"
            @click="onGotoOffers"
          />
          <NavigatableItem
            :tag="AppButton"
            :class="$style.button"
            :active-class="$style.active"
            :text="$t('pages.mediaCard.promo.cancel')"
            @click="onFinish"
          />
        </section>
        <h4 :class="$style.hint">{{ $t('pages.mediaCard.promo.hint') }}</h4>
      </article>
    </section>
  </UIModal>
</template>

<script setup lang="ts">
import * as playerHelpers from '@package/media-player/src/player/helpers';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import BgImage1 from '@SMART/assets/images/my-channel-slider-1.webp';
import BgImage2 from '@SMART/assets/images/my-channel-slider-2.webp';
import BgImage3 from '@SMART/assets/images/my-channel-slider-3.webp';
import BgImage4 from '@SMART/assets/images/my-channel-slider-4.webp';
import {
  customEventsService,
  FocusKeys,
  onboardingService,
  RouterPage,
  routerService,
  type SessionGetters,
  type SessionState,
  storeToRefs,
  useSessionStore,
} from '@SMART/index';
import { computed, onMounted, provide } from 'vue';

import AppButton from '@/components/app-button/AppButton.vue';
import UIModal from '@/components/modal/UIModal.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import useSessionVariables from '@/sdk/session/use-session-variables';

interface Props {
  images?: string[];
}

const props = withDefaults(defineProps<Props>(), {
  images: () => [],
});

const emit = defineEmits<{
  (e: 'finish'): void;
}>();

const { el, focusKey, focusSelf } = useNavigatable({ focusKey: FocusKeys.PROMO_SUB_MODAL, isFocusBoundary: true });
provide('parentFocusKey', focusKey.value);

const slider1 = computed(() => props.images[0] || BgImage1);
const slider2 = computed(() => props.images[1] || BgImage2);
const slider3 = computed(() => props.images[2] || BgImage3);
const slider4 = computed(() => props.images[3] || BgImage4);

const { isActiveSubscription, isPartnerSubscription, isPartnerUser, subscription, currentOffer, hasTrialOffer } =
  storeToRefs<SessionState, SessionGetters, unknown>(useSessionStore());

const { isAuth } = useSessionVariables();

const primaryButtonText = computed(() => {
  const episodeData = {
    season: 0,
    episode: 0,
  };

  return playerHelpers.getPlayButtonText({
    isAuth: isAuth.value,
    isActiveSubscription: isActiveSubscription.value,
    isPartnerSubscription: isPartnerSubscription.value || isPartnerUser.value,
    canContinueWatch: false,
    isVOD: true,
    isLive: false,
    isWatchButtonWithContentTypeText: true,
    offer: currentOffer.value,
    hasTrialOffer: hasTrialOffer.value,
    subscription: subscription.value,
    episodeData,
  });
});

const onFinish = () => {
  onboardingService.finishMyChannel();
  emit('finish');
};

const onGotoOffers = () => {
  routerService.push({ name: RouterPage.Offers });
};

onMounted(() => {
  customEventsService.setOnPressBackCallback(() => onFinish(), true);
  focusSelf();
});
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@package/ui/src/styles/smarttv-fonts';
@import '@/styles/layers';

html:global(.has-backdropFilter) {
  .blur10 {
    filter: blur(10px);
  }

  .blur20 {
    backdrop-filter: blur(20px);
    filter: blur(20px);
  }

  .blur42 {
    background: #ffffff1a;
    backdrop-filter: blur(42px);
    box-shadow: 0px 20px 60px 0px #ffffff40 inset;
  }

  .cropLeft {
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--gradient-to-left);
      content: '';
    }
  }

  .cropRight {
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--gradient-to-right);
      content: '';
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: adjustPx(206px) adjustPx(48px) adjustPx(206px) 0px;
  background: var(--color-notheme-bg-banner-button);
}

.illustration {
  display: flex;
  align-items: center;
  margin-right: adjustPx(48px);
  width: adjustPx(960px);
  height: adjustPx(668px);
  overflow: hidden;

  .border {
    position: absolute;
    z-index: map-get($map: $layers, $key: --z-index-wrapper);
    width: adjustPx(96px);
    height: adjustPx(668px);
    background: var(--gradient-content);

    &Left {
      left: 0;
    }

    &Right {
      left: adjustPx(864px);
    }
  }

  .slides {
    position: fixed;
    top: adjustPx(438px);
    left: adjustPx(120px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: adjustPx(768px);

    .image {
      margin-right: adjustPx(18px);
      width: adjustPx(336px);
      height: adjustPx(216px);
      border-radius: adjustPx(36px);
      opacity: 0.6;
      object-fit: cover;
    }

    .pic3Container {
      position: fixed;
      top: adjustPx(399px);
      left: adjustPx(168px);
      width: adjustPx(472px);
      height: adjustPx(296px);
      border-radius: adjustPx(56px);
    }
    .pic3 {
      width: adjustPx(472px);
      height: adjustPx(296px);
      border-radius: adjustPx(56px);
      opacity: 60%;
    }

    .pic4Container {
      position: fixed;
      top: adjustPx(399px);
      left: adjustPx(347px);
      width: adjustPx(527px);
      height: adjustPx(296px);
      border-radius: adjustPx(56px);
    }
    .pic4 {
      width: adjustPx(527px);
      height: adjustPx(296px);
      border-radius: adjustPx(56px);
      opacity: 60%;
    }

    .cropLeft {
      position: fixed;
      top: adjustPx(359px);
      left: adjustPx(40px);
      width: adjustPx(104px);
      height: adjustPx(376px);

      .pic5 {
        position: absolute;
        top: adjustPx(84px);
        right: 0;
        width: adjustPx(328px);
        max-width: adjustPx(328px);
        height: adjustPx(208px);
        border-radius: adjustPx(36px);
        opacity: 0.6;
        opacity: 40%;
        object-fit: cover;
      }

      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--gradient-to-left);
        content: '';
      }
    }

    .cropRight {
      position: fixed;
      top: adjustPx(359px);
      left: adjustPx(898px);
      width: adjustPx(104px);
      height: adjustPx(376px);

      .pic6 {
        position: absolute;
        top: adjustPx(84px);
        left: 0;
        width: adjustPx(328px);
        max-width: adjustPx(328px);
        height: adjustPx(208px);
        border-radius: adjustPx(36px);
        opacity: 0.6;
        opacity: 40%;
        object-fit: cover;
      }

      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--gradient-to-right);
        content: '';
      }
    }
  }

  .frame {
    position: fixed;
    top: adjustPx(368px);
    left: adjustPx(240px);
    display: flex;
    align-items: center;
    width: adjustPx(540px);
    height: adjustPx(360px);
    border-radius: adjustPx(90px);

    background: var(--color-states-bg-accent-50);
    overflow: hidden;
    box-shadow: 0px 20px 60px 0px #ffffff40 inset;

    &Icon {
      position: absolute;
      top: adjustPx(102px);
      left: adjustPx(192px);
      width: adjustPx(156px);
      height: adjustPx(156px);
    }

    &Slides {
      position: absolute;
      left: adjustPx(-200px);
      display: flex;
      align-items: center;
      width: adjustPx(560px);
      height: adjustPx(376px);
      border-radius: adjustPx(90px);

      .image {
        width: adjustPx(390px);
        height: adjustPx(252px);
        object-fit: cover;
        border-radius: adjustPx(36px);
      }

      .pic1 {
        width: adjustPx(480px);
        height: adjustPx(296px);
      }

      .pic2 {
        margin-right: adjustPx(48px);
        width: adjustPx(368px);
        height: adjustPx(256px);
      }
    }
  }
}

.margin {
  margin-right: adjustPx(48px);
}

.text {
  position: fixed;
  top: adjustPx(280px);
  left: adjustPx(1048px);
  z-index: map-get($map: $layers, $key: --z-index-content);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: adjustPx(864px);
  height: adjustPx(668px);
  background: var(--color-notheme-bg-banner-button);

  .title {
    margin-bottom: adjustPx(32px);

    @include SmartTvTitle-3();
  }

  .subtitle {
    margin-bottom: adjustPx(64px);
    max-width: adjustPx(803px);

    @include SmartTvBody-2();
  }

  .buttons {
    display: flex;

    .button {
      margin-right: adjustPx(18px);
      height: adjustPx(96px);
      border: none;
      border-radius: adjustPx(24px);

      @include SmartTvLabel-2();
    }

    .active {
      background: var(--color-notheme-bg-accent);
      color: var(--color-notheme-text-accent);
    }
  }

  .hint {
    margin-top: adjustPx(64px);

    @include SmartTvBody-3();
  }
}

.borderLeftStub {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($map: $layers, $key: --z-index-heading);
  width: adjustPx(40px);
  background: var(--color-notheme-bg-banner-button);
}
.borderRightStub {
  position: fixed;
  top: 0;
  bottom: 0;
  left: adjustPx(1002px);
  z-index: map-get($map: $layers, $key: --z-index-heading);
  width: adjustPx(50px);
  background: var(--color-notheme-bg-banner-button);
}
</style>
