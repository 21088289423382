import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import useLogger from '@package/logger/src/use-logger';
import type {
  MediaSourceTechAbrLevelQualityChanged,
  MediaSourceTechEventError,
  MediaSourceTechEventFragmentLoaded,
  MediaSourceTechEventManifestParsed,
  MediaSourceTechEventQualityLevelSwitched,
} from '@package/media-player-tech/src/events/media-source-tech-event';
import { MediaSourceTechEvent } from '@package/media-player-tech/src/events/media-source-tech-event';
import AbstractMediaTech, {
  type MediaSourceLoadOptions,
  type MediaSourceTechBufferInfo,
} from '@package/media-player-tech/src/media-source-tech';
import { getFirstElement, isDefined, isNumber, isUndefinedOrNull, toDisposable } from '@package/sdk/src/core';

import loadShakaPlayerModule from '../../tech-loaders/shaka-player-loader';
import { ShakaLoadMode } from './shaka-runtime-error';

interface ShakaAdaptationEvent extends Event {
  oldTrack: shaka.extern.Track | null;
  newTrack: shaka.extern.Track;
}

interface ShakaPlayerMediaTechConstructorOptions {
  shakaConfig: Partial<shaka.extern.PlayerConfiguration>;
}

const logger = useLogger('shaka-player-instance', 'media-player');

let isPolyfillInstalled = false;

declare global {
  interface Window {
    $vijuPlayer?: {
      disposePlayerPromise?: Promise<any>;
    };
  }
}

export default class ShakaPlayerMediaTech extends AbstractMediaTech<HTMLVideoElement> {
  private tech: shaka.Player;
  private manifestUrl: string;

  private readonly defaultShakaConfig: Partial<shaka.extern.PlayerConfiguration>;
  // Статичный, так как требуется только один инстанс
  private static offlineStorage: shaka.offline.Storage;
  private static offlineTech: shaka.Player;

  constructor(options: ShakaPlayerMediaTechConstructorOptions) {
    super();

    this.defaultShakaConfig = options.shakaConfig;
  }

  public get shakaInstance(): shaka.Player {
    return this.tech;
  }

  public get audioCodec(): string {
    if (!this.tech) {
      return '';
    }

    const audioCodec = getFirstElement(this.tech.getVariantTracks())?.audioCodec;

    return audioCodec || '';
  }

  public get videoCodec(): string {
    if (!this.tech) {
      return '';
    }

    const videoCodec = getFirstElement(this.tech.getVariantTracks())?.videoCodec;

    return videoCodec || '';
  }

  public get bandwidth(): number {
    if (!this.tech) {
      return 0;
    }

    const { estimatedBandwidth } = this.tech.getStats();

    return estimatedBandwidth / 1024;
  }

  public get buffer(): MediaSourceTechBufferInfo {
    const defaultBufferRange: MediaSourceTechBufferInfo = { length: 0, start: 0 };

    if (!this.tech) {
      return defaultBufferRange;
    }

    const { video } = this.tech.getBufferedInfo();
    const bufferedRange = getFirstElement(video);

    if (!bufferedRange) {
      return defaultBufferRange;
    }

    const { start, end } = bufferedRange;

    return {
      start,
      length: end - start,
    };
  }

  public get currentQualityLevelHeight(): number {
    if (!this.tech) {
      return 0;
    }

    const { height } = this.tech.getStats();

    return height;
  }

  public getNextLevel(): number {
    return 0;
  }

  public async init(): Promise<void> {
    const ShakaModule = await loadShakaPlayerModule();

    if (!isPolyfillInstalled) {
      ShakaModule.polyfill.installAll();
      isPolyfillInstalled = true;
    }

    this.tech = new ShakaModule.Player();

    const isOfflineStorageSupported = ShakaModule.offline.Storage.support();

    if (!ShakaPlayerMediaTech.offlineTech && isOfflineStorageSupported) {
      ShakaPlayerMediaTech.offlineTech = new ShakaModule.Player();
    }

    if (!ShakaPlayerMediaTech.offlineStorage && isOfflineStorageSupported) {
      ShakaPlayerMediaTech.offlineStorage = new ShakaModule.offline.Storage(ShakaPlayerMediaTech.offlineTech);
    }

    const shakaConfig = {
      restrictions: {
        ...this.defaultShakaConfig.restrictions,
      },
      abr: {
        ...this.defaultShakaConfig.abr,
        enabled: true,
        restrictToElementSize: true,
      },
      streaming: {
        ...this.defaultShakaConfig.streaming,
      },
      manifest: {
        ...this.defaultShakaConfig.manifest,
        raiseFatalErrorOnManifestUpdateRequestFailure: true,
        retryParameters: {
          baseDelay: 100,
          timeout: ConstantsConfigPlayer.getProperty('httpRequestTimeoutMs'),
          maxAttempts: 20,
          backoffFactor: 1.2,
        },
      },
    };

    this.tech.configure(shakaConfig);

    if (ShakaPlayerMediaTech.offlineTech) {
      ShakaPlayerMediaTech.offlineTech.configure(shakaConfig);
    }

    this.disposableStore.add(
      toDisposable(() => {
        window.$vijuPlayer.disposePlayerPromise = Promise.all([
          this.tech.destroyAllPreloads(),
          this.tech.unload(),
          this.tech.detach(),
          this.tech.destroy(),
        ]);
      }),
    );

    this.registerListeners();
  }

  public get latency(): number {
    if (!this.tech) {
      return 0;
    }

    const { loadLatency } = this.tech.getStats();

    return loadLatency;
  }

  public async attachMedia(element: HTMLVideoElement): Promise<void> {
    await this.tech.attach(element);
  }

  public async loadSource(options: MediaSourceLoadOptions): Promise<void> {
    const { src, isLive, offset } = options;

    this.manifestUrl = src;

    const currentLoadMode = this.tech.getLoadMode() as unknown as ShakaLoadMode;

    if (currentLoadMode === ShakaLoadMode.MEDIA_SOURCE) {
      try {
        await this.tech.unload();
      } catch (error) {
        logger.error(error);
      }
    }

    try {
      const storedContent = isLive ? undefined : await this.getOfflineContentByURI(src);

      const assetOurUriPreloader = storedContent?.offlineUri || src;

      await this.tech.load(assetOurUriPreloader, isLive ? null : offset);
    } catch (error) {
      logger.error(error);

      this.onError(error);
    }
  }

  public async recoverMediaError() {
    const isStreamingRecovered = this.tech.retryStreaming(1);

    logger.warn('isStreamingRecovered', isStreamingRecovered);

    // shaka не смогла восстановить самостоятельно, делаем жесткий релоад плеера
    if (!isStreamingRecovered) {
      // Очень важно, сначала сделать у shaka unload() текущего источника, и только потом грузить новый
      await this.tech.unload();
      await this.tech.load(this.manifestUrl);
    }
  }

  public setNextLevel(levelId: number): void {
    const levels = this.tech.getVariantTracks();

    if (levelId === -1) {
      this.tech.configure({ abr: { enabled: true } });
      return;
    }

    const currentLevel = levels.find((level) => level.id === levelId);

    if (!currentLevel) {
      return;
    }

    // Говорим shaka, что не нужно автоматически искать качество получше
    this.tech.configure({ abr: { enabled: false } });
    this.tech.selectVariantTrack(currentLevel, false);
  }

  public startLoad(offset: number | undefined): void {
    if (isDefined(offset)) {
      this.tech.updateStartTime(offset);
    }
  }

  public async stopLoad(): Promise<void> {
    this.tech.destroyAllPreloads();
    await this.tech.unload();
  }

  public async requestSaveMediaOffline(url: string): Promise<void> {
    const content = await this.getOfflineContentByURI(url);

    if (content) {
      return;
    }

    await this.handleMaxOfflineStorageSize();

    // Промиз резолвится, как закончится загрузка
    return ShakaPlayerMediaTech.offlineStorage.store(url).promise;
  }

  public async clearOfflineCache(): Promise<void> {
    const ShakaModule = await loadShakaPlayerModule();

    await ShakaModule.offline.Storage.deleteAll();
  }

  private async handleMaxOfflineStorageSize() {
    const list = await ShakaPlayerMediaTech.offlineStorage.list();

    if (list.length >= 20) {
      await this.clearOfflineCache();
    }
  }

  private async getOfflineContentByURI(url: string) {
    const list = await ShakaPlayerMediaTech.offlineStorage.list();

    const content = list.find((content) => content.originalManifestUri === url);

    /**
     * Выкачивали, но не докачали. Считаем что контент битый, и не даем его дальше проигрывать. Ну и чистим из стора
     */
    if (content?.isIncomplete) {
      await ShakaPlayerMediaTech.offlineStorage.remove(url);
      return undefined;
    }

    return content;
  }

  private onError = (error: Event) => {
    const normalizedError = error as unknown as shaka.util.Error;

    logger.error(error);

    // Пока ожидаем что тут всегда ошибка от shaka
    if (isUndefinedOrNull(normalizedError.category)) {
      return;
    }

    // Ошибка не shaka
    if (!normalizedError.severity || normalizedError.severity === 2.0) {
      return this.emitter.emit(
        'error',
        new MediaSourceTechEvent<MediaSourceTechEventError>({
          tech: 'shaka',
          originalEvent: error,
          data: {
            fatal: false,
            errorType: 'manifest-parsing-error',
          },
        }),
      );
    }

    this.emitter.emit(
      'error',
      new MediaSourceTechEvent<MediaSourceTechEventError>({
        tech: 'shaka',
        originalEvent: error,
        data: {
          fatal: false,
          errorType: 'buffer-error',
        },
      }),
    );
  };

  protected registerListeners(): void {
    const onManifestParsed = (event: Event) => {
      const levels = this.tech
        .getVariantTracks()
        .filter((level) => isNumber(level.width) && isNumber(level.height) && isNumber(level.id))
        .sort((a, b) => (a.height as number) - (b.height as number));

      this.emitter.emit(
        'manifest-parsed',
        new MediaSourceTechEvent<MediaSourceTechEventManifestParsed>({
          tech: 'shaka',
          originalEvent: event,
          data: {
            qualityLevels: levels.map((level) => ({
              width: level.width as number,
              height: level.height as number,
              id: level.id,
            })),
          },
        }),
      );
    };

    const onQualityLevelSwitched = (event: Event) => {
      const levels = this.tech.getVariantTracks();

      const currentLevel = levels.find((level) => level.active);

      if (!currentLevel) {
        return;
      }

      const { oldTrack, newTrack } = event as ShakaAdaptationEvent;

      if (oldTrack?.height && newTrack.height) {
        this.emitter.emit(
          'abr-quality-level-changed',
          new MediaSourceTechEvent<MediaSourceTechAbrLevelQualityChanged>({
            tech: 'shaka',
            originalEvent: event,
            data: {
              oldLevelHeight: oldTrack.height,
              newLevelHeight: newTrack.height,
            },
          }),
        );
      }

      this.emitter.emit(
        'quality-level-switched',
        new MediaSourceTechEvent<MediaSourceTechEventQualityLevelSwitched>({
          tech: 'shaka',
          originalEvent: event,
          data: {
            level: currentLevel.id,
          },
        }),
      );
    };

    const onStallDetected = (event: Event) => {
      this.emitter.emit(
        'error',
        new MediaSourceTechEvent<MediaSourceTechEventError>({
          tech: 'shaka',
          originalEvent: event,
          data: {
            fatal: false,
            errorType: 'buffer-error',
          },
        }),
      );
    };

    const onFragmentLoaded = (event: Event) => {
      const { start: seekStart, end: seekEnd } = this.tech.seekRange();

      /**
       * https://github.com/shaka-project/shaka-player/issues/1052
       * @type {number}
       */
      const levelTotalDuration = seekEnd - seekStart;

      this.emitter.emit(
        'fragment-loaded',
        new MediaSourceTechEvent<MediaSourceTechEventFragmentLoaded>({
          tech: 'shaka',
          originalEvent: event,
          data: {
            startFragmentTime: seekStart,
            endFragmentTime: seekEnd,
            levelTotalDuration,
          },
        }),
      );
    };

    // Подгрузился новый кусок
    this.tech.addEventListener('segmentappended', onFragmentLoaded);
    // Когда проигрывание вдруг застопорилось (упрерлись в буффер), одна дальше играть можем
    this.tech.addEventListener('stalldetected', onStallDetected);
    // Ошибка, может критичная, а может и нет. UI плеера разберется
    this.tech.addEventListener('error', this.onError);
    // Событие, когда видео готово к проигрыванию, к этому момент также уже распаршен манифест.
    // Есть еще событие manifestparsed, одна shaka рекомендует это 'streaming' событие.
    this.tech.addEventListener('streaming', onManifestParsed);
    // Если качество поменялось автоматически, из-за качества интернета
    this.tech.addEventListener('adaptation', onQualityLevelSwitched);
    // Если мы сами (руками) поменяли качество в плеере
    this.tech.addEventListener('variantchanged', onQualityLevelSwitched);
  }

  public dispose() {
    super.dispose();
  }

  public pause(): void {
    this.videoEl.pause();
  }

  public play(): Promise<void> {
    return this.videoEl.play();
  }

  public seekTo(offset: number): void {
    this.videoEl.currentTime = offset;
  }
}
