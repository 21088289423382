import { SmartTvVijuPlayer } from '@package/media-player/src/player';
import type { PlayerInstanceConstructorOptions } from '@package/media-player/src/player/modules/instance/interfaces';
import { FeatureToggle } from '@SDK/api';
import {
  analyticService,
  deviceService,
  environmentService,
  featuresService,
  usePlayerSentryError,
} from '@SMART/index';
import { onBeforeUnmount } from 'vue';

export default function useSmartTVPlayer(options: PlayerInstanceConstructorOptions) {
  const { startRecordingPlayerExceptions } = usePlayerSentryError();

  const isRelease = environmentService.getVariable<boolean>('isRelease');

  const defaultOptions: Partial<PlayerInstanceConstructorOptions> = {
    muted: false,
    autoplay: true,
  };

  const _options = {
    ...defaultOptions,
    ...options,
  };

  const player = new SmartTvVijuPlayer(_options);
  startRecordingPlayerExceptions(player);

  const isShakaPlayerApiAvailable = deviceService.getAvailableFeatures().includes('hasShakaApiEnabled');

  player.setConfigProperties({
    'experimental.feature.shakaPlayerAPI': isShakaPlayerApiAvailable,
    'system.isStreamingAnalyticsEnabled': featuresService.getFeatureFlag(
      FeatureToggle.WebSmartTvStreamingAnalyticsEnabled,
    ).status,
    'system.isDebugEnabled': !isRelease || featuresService.getFeatureFlag(FeatureToggle.WebSmartTvDebugEnabled).status,
    'analytic.metadata': { page: analyticService.getAnalyticPageName() },
  });

  onBeforeUnmount(() => {
    player.endMediaSession();
  });

  return player;
}
