<template>
  <Transition>
    <section :class="$style.navigation">
      <NavigatableItem
        v-if="!isBackButtonDisabled"
        :tag="AppButton"
        :extra-props="extraProps"
        :class="$style.button"
        :active-class="$style.active"
        @active="$emit('active')"
        @click="onNextClick(-1)"
      >
        <template #icon>
          <PrevIcon :class="$style.icon" />
        </template>
      </NavigatableItem>
      <div v-else :class="$style.stub" />
      <NavigatableItem
        :focus-key="FocusKeys.PLAY_BUTTON"
        :tag="AppButton"
        :extra-props="extraProps"
        :class="$style.button"
        :active-class="$style.active"
        @active="$emit('active')"
        @click="$emit('play', !isPlaying)"
      >
        <template #icon>
          <PauseIcon v-if="isPlaying" :class="$style.icon" />
          <PlayIcon v-else :class="$style.icon" />
        </template>
      </NavigatableItem>
      <NavigatableItem
        v-if="!isNextButtonDisabled"
        :tag="AppButton"
        :extra-props="extraProps"
        :class="$style.button"
        :active-class="$style.active"
        @active="$emit('active')"
        @click="onNextClick(+1)"
      >
        <template #icon>
          <NextIcon :class="$style.icon" />
        </template>
      </NavigatableItem>
      <div v-else :class="$style.stub" />
    </section>
  </Transition>
</template>

<script setup lang="ts">
import { throttleWithImmediate } from '@package/sdk/src/core';
import NextIcon from '@SMART/assets/icons/51x51/audio-next.svg';
import PrevIcon from '@SMART/assets/icons/51x51/audio-prev.svg';
import PauseIcon from '@SMART/assets/icons/51x51/pause.svg';
import PlayIcon from '@SMART/assets/icons/51x51/play.svg';
import { FocusKeys } from '@SMART/index';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

const CLICK_TIMEOUT_MS = 1_500;

interface Props {
  isBackButtonDisabled: boolean;
  isNextButtonDisabled: boolean;
  isPlaying: boolean;
  extraProps?: Record<string, any>;
}

defineProps<Props>();

const emit = defineEmits(['next', 'play', 'active']);

const onNextClick = throttleWithImmediate(
  (step: number) => {
    emit('next', step);
  },
  { timeout: CLICK_TIMEOUT_MS, immediate: true },
);
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.navigation {
  position: absolute;
  top: adjustPx(490px);
  left: adjustPx(32px);
  right: adjustPx(48px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stub {
  width: adjustPx(96px);
  height: adjustPx(96px);
  background: transparent;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: adjustPx(24px);
  width: adjustPx(96px);
  height: adjustPx(96px);
  border: none;
  border-radius: adjustPx(24px);
}

.active {
  background: var(--color-bg-accent);
  color: var(--color-notheme-text-accent);
}

.icon {
  width: adjustPx(51px);
  height: adjustPx(51px);
}
</style>
