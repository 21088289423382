import { EventEmitter } from '@package/sdk/src/core';

import type { DsmlRequestBodyV2Params } from '@/core/code/dsml-v2-request-transformer';

import type { DsmlEventError } from '../platform/errors';

export interface DsmlEventMap {
  error: DsmlEventError;
  event: DsmlRequestBodyV2Params;
}

const emitter = new EventEmitter<DsmlEventMap>();

export default emitter;
