<template>
  <div
    v-for="(column, columnIndex) in items.columns"
    :key="`column-${columnIndex}`"
    :class="[$attrs.class, column.type !== 'button' && mapClasses($style, column.class)]"
  >
    <div v-for="(row, rowIndex) in column.rows" :key="`column-${rowIndex}`">
      <div
        v-if="row.type === 'text'"
        :class="[$style.shimmer, mapClasses($style, row.variant), mapClasses($style, row.class)]"
      />
      <div v-else-if="row.type === 'image'" :class="[$style.images, mapClasses($style, row.class)]">
        <div
          v-for="rowItem in row.rowItems"
          :key="rowItem"
          :class="[$style.shimmer, $style.image, mapClasses($style, row.variant)]"
        />
      </div>
      <div v-else-if="row.type === 'qr'" :class="[$style.shimmer, $style.qr, mapClasses($style, row.class)]" />
      <div v-else-if="row.type === 'button'" :class="[$style.shimmer, $style.button, mapClasses($style, row.class)]" />
      <shimmer-layout v-if="!row.type" :items="row" />
    </div>
    <div v-if="column.type === 'button'" :class="[$style.shimmer, $style.button, mapClasses($style, column.class)]" />
  </div>
  <div v-for="(row, rowIndex) in items.rows" :key="`row-${rowIndex}`" :class="mapClasses($style, row.class)">
    <shimmer-layout v-if="row.columns" :items="row" />
  </div>
</template>

<script setup lang="ts">
import { type Block } from './useShimmers';

interface Props {
  items: Block;
}

defineProps<Props>();

const mapClasses = (style: Record<string, string>, classes?: string) => classes?.split(' ').map((x) => style[x]);
</script>

<style module lang="scss">
@use '@package/ui/src/styles/shimmers' as shimmers;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@import '@package/ui/src/styles/shimmers';

.title {
  @include shimmers.SmartTvShimmerTitle();
}

.subtitle {
  @include shimmers.SmartTvShimmerSubtitle();
}

.subtitle2 {
  @include shimmers.SmartTvShimmerSubtitle2();
}

.body {
  @include shimmers.SmartTvShimmerBody();
}

.mb-40 {
  margin-bottom: adjust.adjustPx(40px);
}

.mb-48 {
  margin-bottom: adjust.adjustPx(48px);
}

.mb-24 {
  margin-bottom: adjust.adjustPx(24px);
}

.mb-100 {
  margin-bottom: adjust.adjustPx(100px);
}

.ml-24 {
  margin-left: adjust.adjustPx(24px);
}

.ml-70 {
  margin-left: adjust.adjustPx(70px);
}

.w-50 {
  width: 50%;
}

.w-52 {
  width: 52%;
}

.w-100 {
  width: 100%;
}

.w-btn-lg {
  width: adjust.adjustPx(435px);
  height: adjust.adjustPx(96px);
}

.w-btn-md {
  width: adjust.adjustPx(283px);
  height: adjust.adjustPx(96px);
}

.h100-40 {
  height: calc(100% - 40px);
}

.border-right {
  max-height: adjust.adjustPx(732px);
  border-right: adjust.adjustPx(2px) solid var(--color-stroke-div-primary);
}

.flex {
  display: flex;
}

.center {
  justify-content: center;
}

.align-end {
  align-items: end;
}

.images {
  display: flex;
}

.image {
  margin-right: adjust.adjustPx(24px);
  width: adjust.adjustPx(296px);
  height: adjust.adjustPx(400px);
  border-radius: adjust.adjustPx(24px);
}

.medium {
  width: adjust.adjustPx(315px);
  max-width: adjust.adjustPx(315px);
  height: adjust.adjustPx(430px);
}

.small {
  width: adjust.adjustPx(240px);
  max-width: adjust.adjustPx(240px);
  height: adjust.adjustPx(336px);
}

.kinom {
  width: adjust.adjustPx(370px);
  height: adjust.adjustPx(224px);
  overflow: hidden;
}

.qr {
  width: adjust.adjustPx(432px);
  height: adjust.adjustPx(432px);

  border-radius: adjust.adjustPx(24px);
}

.button {
  border-radius: adjust.adjustPx(16px);
}
</style>
