<template>
  <section ref="container" :class="$style.wrapper">
    <h2 :class="$style.title">{{ title }}</h2>

    <div ref="seasonsContainer">
      <media-card-seasons-list
        :active-season="activeSeason"
        :seasons="items"
        @click="onSelectSeason"
        @active="handleActiveSeason"
      />
    </div>

    <div ref="el">
      <ScrollViewport
        tag="ul"
        :class="{ [$style.list]: true, [$style.listDescription]: isDefined(items[0]?.description) }"
        :x="offsetEpisodesLeftPx"
        role="list"
      >
        <li v-for="(item, index) in currentSeason.episodes" :key="item.id" :class="$style.item">
          <label
            v-if="canWatchFreeEpisode && !isUnavailable(item)"
            :class="{ [$style.tag]: true, [$style.tagAvailable]: item.accessKind === ContentAccessTypes.AllUsers }"
          >
            <span v-if="item.accessKind === ContentAccessTypes.AllUsers">{{ $t('pages.mediaCard.watchNow') }}</span>
            <span v-else>
              {{ $t('pages.mediaCard.watchAfterSub') }}
            </span>
          </label>

          <NavigatableItem
            :class="$style.link"
            :tag="AppSlotButton"
            :data-testid="`episodes-${index + 1}`"
            :prop-parent-focus-key="FocusKeys.MEDIA_CARD_EPISODES_SLIDER"
            :data-index="index"
            :disabled="isUnavailable(item) && isInactivePartnerSubscription"
            data-can-focus-while-disabled
            @active="(element) => handleActiveEpisode(item, element as HTMLElement)"
            @click="onPlayEpisode(item, index)"
          >
            <app-image :src="item.preview || item.poster" :class="$style.preview" :width="550" />
            <div v-if="!isUnavailable(item)" :class="$style.textGradient" />
            <div v-if="item.watchingItem?.offset && !isCheckIconShown(item)" :class="$style.timelineBg">
              <div :class="$style.timeline" :style="{ width: getTimelineWidthInPercent(item) }" />
            </div>

            <p v-if="!isUnavailable(item)" :class="$style.duration">
              {{ formatDuration(item.duration, 'hhmm') }}
            </p>

            <p v-if="isUnavailable(item)" :class="$style.availability">
              {{ $t('pages.mediaCard.availability.unavailableItem') }}
            </p>
            <PlayIcon v-if="isPlayIconShown(item)" :class="$style.playIcon" />
            <CheckIcon v-if="isCheckIconShown(item)" :class="$style.checkIcon" />
          </NavigatableItem>

          <section :class="$style.subtitle">
            <p>{{ index + 1 }}.&nbsp;{{ item.title }}</p>
          </section>
          <section v-if="item.description" :class="$style.description">
            <p>{{ item.description }}</p>
          </section>
        </li>
      </ScrollViewport>
    </div>
  </section>
</template>

<script setup lang="ts">
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import useMediaContentAvailability from '@package/content-utils/src/code/use-media-content-availability';
import { useContentPageAnalytics } from '@package/sdk/src/analytics';
import { ContentAccessTypes, type Episode, type Season, type WatchingItem } from '@package/sdk/src/api';
import { isDefined, toPercent } from '@package/sdk/src/core';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import CheckIcon from '@SMART/assets/icons/33x33/check.svg';
import PlayIcon from '@SMART/assets/icons/96x96/play.svg';
import {
  analyticService,
  FocusKeys,
  formatDuration,
  type SessionGetters,
  type SessionState,
  storeToRefs,
  useSessionStore,
  useSliderOffset,
} from '@SMART/index';
import { computed, ref } from 'vue';

import AppImage from '@/components/app-image/AppImage.vue';
import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';
import MediaCardSeasonsList from '@/pages/media-card/components/MediaCardSeasonsList.vue';
import useSessionVariables from '@/sdk/session/use-session-variables';

const { el } = useNavigatable({
  focusKey: FocusKeys.MEDIA_CARD_EPISODES_SLIDER,
  saveLastFocusedChild: true,
  isFocusBoundary: true,
  focusBoundaryDirections: ['right'],
  hasGlobalAccess: true,
  onBlur: () => {
    activeEpisode.value = undefined;
  },
});

interface Props {
  items: Season[];
  title: string;
  contentWatchingItem?: WatchingItem;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (event: 'activated', offset: number, item: Episode): void;
  (event: 'selected', episode: Episode, episodeIndex: number, activeSeasonId: number): void;
}>();
const contentPageAnalytics = useContentPageAnalytics(analyticService.sender);

const seasonsContainer = ref<HTMLElement>();

const container = ref<HTMLElement>();
const activeSeason = ref(0);
const activeEpisode = ref<Episode>();
const { handleUpdateOffset: handleUpdateEpisodeOffset, offsetLeftPx: offsetEpisodesLeftPx } = useSliderOffset();
const { isUnavailable, isAvailableSoon } = useMediaContentAvailability();
const { isInactivePartnerSubscription } = useSessionVariables();

const { isActiveSubscription, isPartnerSubscription } = storeToRefs<SessionState, SessionGetters, unknown>(
  useSessionStore(),
);
const currentSeason = computed(() => props.items[activeSeason.value]);
const firstEpisode = computed(() => props.items[0].episodes[0]);
const hasFreeEpisode = computed(() => firstEpisode.value.accessKind === ContentAccessTypes.AllUsers);
const canWatchFreeEpisode = computed(
  () => !isActiveSubscription.value && !isPartnerSubscription.value && hasFreeEpisode.value,
);

const isPlayIconShown = (item: Episode) =>
  !(canWatchFreeEpisode.value && !isUnavailable(item)) && !isUnavailable(item) && activeEpisode.value?.id === item.id;

const getProgressPercentage = (offset: number | undefined, duration: number) => {
  if (!duration || !offset) {
    return 0;
  }

  return Math.ceil((offset * 100) / duration);
};

const isCheckIconShown = (item: Episode) =>
  !(canWatchFreeEpisode.value && !isUnavailable(item)) &&
  !isUnavailable(item) &&
  isDefined(item.watchingItem) &&
  getProgressPercentage(item.watchingItem.offset, item.duration) >=
    Number(ConstantsConfigInstanceSmartTV.getProperty('userWatchingItemUpdateThreshold')) * 100;

const getTimelineWidthInPercent = (episode: Episode) => {
  const offset = episode.watchingItem?.offset;
  const isUnavailableEpisode = isUnavailable(episode) || isAvailableSoon(episode);

  if (!offset || isUnavailableEpisode) {
    return toPercent(0);
  }

  const percents = (offset / episode.duration) * 100;

  return toPercent(percents);
};

const handleActiveSeason = (index: number) => {
  if (!seasonsContainer.value) {
    return;
  }

  emit('activated', container.value?.offsetTop || 0, props.items[index]?.episodes[0]);

  onSelectSeason(index);
};

const handleActiveEpisode = (item: Episode, element: HTMLElement) => {
  activeEpisode.value = item;
  handleUpdateEpisodeOffset(element, el.value.offsetWidth);
  emit('activated', container.value?.offsetTop || 0, item);
};

const onPlayEpisode = (episode: Episode, episodeIndex: number) => {
  contentPageAnalytics.onClickItemSelectEpisode({
    itemId: currentSeason.value.id,
    episodeId: episode.id,
  });

  emit('selected', episode, episodeIndex, activeSeason.value);
};

const onSelectSeason = (index: number) => (activeSeason.value = index);
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.title {
  margin-bottom: adjust.adjustPx(32px);

  @include smartTvFonts.SmartTvSubtitle-1();
}

.list {
  display: flex;
  height: adjust.adjustPx(344px);
}

.listDescription {
  height: adjust.adjustPx(464px);
}
.item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: adjust.adjustPx(32px);
  width: adjust.adjustPx(480px);
  min-width: adjust.adjustPx(480px);
  height: adjust.adjustPx(280px);
  min-height: adjust.adjustPx(280px);
  border-radius: adjust.adjustPx(24px);
  outline: none;
  background-color: var(--color-bg-tertiary);

  img {
    height: 100%;
    object-fit: cover;
  }

  &:last-child {
    margin-right: 0;
  }

  .subtitle {
    position: absolute;
    top: adjust.adjustPx(284px);
    left: adjust.adjustPx(8px);
    display: -webkit-box;
    min-width: adjust.adjustPx(480px);
    overflow: hidden;
    color: var(--color-notheme-text-primary);
    text-align: start;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;

    @include smartTvFonts.SmartTvBody-1();
  }
  .description {
    position: absolute;
    top: adjust.adjustPx(330px);
    left: adjust.adjustPx(8px);
    display: -webkit-box;
    min-width: adjust.adjustPx(480px);
    overflow: hidden;
    color: var(--color-notheme-text-secondary);
    text-align: start;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;

    @include smartTvFonts.SmartTvBody-3();
  }
}

.availability {
  position: absolute;
  left: 0;
  right: 0;
  z-index: map-get($map: $layers, $key: --z-index-heading);
  width: 100%;
  color: var(--color-text-secondary);
  margin-inline: auto;
  text-align: center;

  @include smartTvFonts.SmartTvBody-2();
}

.playIcon {
  position: absolute;
  left: 50%;
  right: 0;
  z-index: map-get($map: $layers, $key: --z-index-heading);
  transform: translateX(-50%);
  width: adjust.adjustPx(96px);
  height: adjust.adjustPx(96px);
}
.checkIcon {
  position: absolute;
  top: adjust.adjustPx(28px);
  left: adjust.adjustPx(28px);
  z-index: map-get($map: $layers, $key: --z-index-heading);
  padding: adjust.adjustPx(4px);
  width: adjust.adjustPx(33px);
  height: adjust.adjustPx(33px);
  border-radius: 50%;
  background-color: var(--color-bg-accent);
}

.duration {
  position: absolute;
  bottom: adjust.adjustPx(30px);
  right: adjust.adjustPx(24px);
  color: var(--color-text-secondary);

  @include smartTvFonts.SmartTvBody-3();
}

.link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: adjust.adjustPx(24px);
  outline: none;
  overflow: hidden;

  .textGradient {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    transform: rotate(180deg);
    background: linear-gradient(180deg, rgba(22, 21, 26, 1) 0%, rgba(22, 21, 26, 0) 100%);
    content: '';
  }

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: adjust.adjustPx(24px);
    content: '';
    background: var(--color-notheme-dim-black-40);
  }

  &:hover::after,
  &.active::after {
    box-shadow: inset 0 0 0 adjust.adjustPx(7px) var(--color-bg-accent);
  }
}

.timeline {
  position: absolute;
  z-index: 2;
  height: adjust.adjustPx(6px);
  border-radius: adjust.adjustPx(4px);
  background-color: var(--color-notheme-bg-accent);
}
.timelineBg {
  position: absolute;
  bottom: adjust.adjustPx(20px);
  left: adjust.adjustPx(26px);
  right: adjust.adjustPx(26px);
  height: adjust.adjustPx(6px);
  border-radius: adjust.adjustPx(4px);
  background-color: var(--color-bg-ghost);
}

.tag {
  position: absolute;
  top: adjust.adjustPx(16px);
  right: adjust.adjustPx(16px);
  z-index: 99;
  padding: adjust.adjustPx(16px) adjust.adjustPx(24px) adjust.adjustPx(16px) adjust.adjustPx(24px);
  width: fit-content;
  height: adjust.adjustPx(72px);
  border-radius: adjust.adjustPx(12px);
  background: var(--color-notheme-dim-black-60);
  color: var(--color-notheme-text-primary);
  white-space: nowrap;

  @include smartTvFonts.SmartTvLabel-3();

  &Available {
    color: var(--color-notheme-text-action);
  }
}
</style>
