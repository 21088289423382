<template>
  <timeline-frame-preview-image
    ref="el"
    :src="previewSrc"
    :on-click="onRequestEpisode"
    :class="{
      [$style.wrapper]: true,
      [$style.wrapperAnimated]: isButtonAnimated && !isSmartTV,
      [$style.smartTvActive]: isSmartTV && focused,
    }"
  >
    <template #text>
      <div :class="$style.timelineContainer">
        <div v-if="isSmartTV" :class="$style.timeline" :style="{ width: timelineAnimation.progress.value + '%' }" />

        <p :class="$style.description">
          <span :class="$style.text">{{ $t('vod.nextMedia') }}</span>
          <span :class="$style.time">{{ episodeTitle }}</span>
        </p>
      </div>
    </template>
  </timeline-frame-preview-image>
</template>

<script setup lang="ts">
import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import type { AnyFunction } from '@PLAYER/player/base/function';
import TimelineFramePreviewImage from '@PLAYER/player/components/timeline/TimelineFramePreviewImage.vue';
import { useFpsAnimation } from '@PLAYER/player/modules/animation/use-fps-animation';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import { PlayerFocusKeys } from '@PLAYER/player/versions/smart/modules/smart-navigation/focus-keys';
import useSmartNavigatable from '@PLAYER/player/versions/smart/modules/smart-navigation/use-smarttv-navigatable';
import { onMounted, watch } from 'vue';

const props = defineProps<{
  previewSrc: string;
  onRequestEpisode: AnyFunction;
  episodeTitle: string;
  isButtonAnimated: boolean;
}>();

const { isSmartTV } = usePlatform();
const { useNavigatable, SpatialNavigation } = useSmartNavigatable();

const { el, focused } = useNavigatable({
  onEnterPress: props.onRequestEpisode,
  focusKey: PlayerFocusKeys.PLAYER_NEXT_EPISODE_PREVIEW_BUTTON,
});

const animationDuration = ConstantsConfigPlayer.getProperty('secondsToTheNextEpisode');
const timelineAnimation = useFpsAnimation(animationDuration, 1);

if (isSmartTV) {
  onMounted(() => {
    if (props.isButtonAnimated) {
      timelineAnimation.start();
    }

    SpatialNavigation?.setFocus(PlayerFocusKeys.PLAYER_NEXT_EPISODE_PREVIEW_BUTTON);
  });

  watch(
    () => props.isButtonAnimated,
    (value) => {
      value ? timelineAnimation.start() : timelineAnimation.stop();
    },
    { immediate: true },
  );
}
</script>

<style module lang="scss">
@use '@package/ui/src/styles/fonts.scss' as webFonts;
@use '@package/ui/src/styles/smarttv-fonts.scss' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.wrapper {
  position: relative;
  overflow: hidden;
}

.wrapperAnimated {
  --next-episode-animation-duration: 6s;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-index-next-episode-button-pseudo);
    width: 0;
    background-color: var(--color-notheme-bg-tertiary-80);
    animation: var(--next-episode-animation-duration) linear button-filling;
  }
}

.description {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--g-spacing-4) var(--g-spacing-8) var(--g-spacing-6);
}

.text {
  color: var(--color-notheme-text-primary);

  @include webFonts.WebLabel-3();
}

.time {
  margin-top: var(--g-spacing-2);
  color: var(--color-notheme-text-secondary);
  text-transform: uppercase;

  @include webFonts.WebBody-3();
}

.smartTvActive {
  border: adjust.adjustPx(8px) solid var(--color-notheme-bg-accent);
}

.timelineContainer {
  position: relative;
  width: 100%;
}

.timeline {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-notheme-bg-hover-70);
}

@keyframes button-filling {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
